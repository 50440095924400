import React from "react"
import Container from "../../styles/styled-component/Container"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Section from "../../styles/styled-component/Section"
import {
  TabContainer,
  TabItem,
  TabMenu,
  Tab,
  SpecsWrapper,
  SpecsTitle,
  SpecsList,
  SpecsContainer,
} from "./specificationsElements"
import { Trans } from "gatsby-plugin-react-i18next"
import { SpecsDiv } from "../zero/zeroElements"
const Specs = ({ isSelected, toggle }) => {
  let specsTitles = [
    <Trans>Stops</Trans>,
    <Trans>Speed</Trans>,
    <Trans>Pit</Trans>,
    <Trans>Headroom</Trans>,
    <Trans>Travel</Trans>,
    <Trans>Power Supply</Trans>,
    <Trans>Traction</Trans>,
    <Trans>Capacity</Trans>,
    <Trans>Doors</Trans>,
    <Trans>Motor Power</Trans>,
    <Trans>Norms</Trans>,
  ]
  let mrlSpecs = [
    <Trans>7</Trans>,
    <Trans>0.15 m/s (up to 0,30 m/s)</Trans>,
    <Trans>180 mm min</Trans>,
    <Trans>2450mm</Trans>,
    <Trans>25000mm max</Trans>,
    <Trans>220v Single-phase Three phase</Trans>,
    <Trans>Gearless motor</Trans>,
    <Trans>From 250kg to 400kg</Trans>,
    <Trans>Automatic or swing</Trans>,
    <Trans>From 0,6 to 1,2 kw</Trans>,
    <Trans>EN81.41, 2006/42/CE</Trans>,
  ]
  let hydraulicSpecs = [
    <Trans>7</Trans>,
    <Trans>0.15 m/s (up to 0,30 m/s)</Trans>,
    <Trans>180 mm min</Trans>,
    <Trans>2450mm</Trans>,
    <Trans>25000mm max</Trans>,
    <Trans>220v Single-phase Three phase</Trans>,
    <Trans>Hydraulic</Trans>,
    <Trans>From 250kg to 650kg</Trans>,
    <Trans>Automatic or swing</Trans>,
    <Trans>from 1.5 to 4 kw</Trans>,
    <Trans>EN81.41, 2006/42/CE</Trans>,
  ]
  return (
    <Section bgColor="#f7f8fc" minHeight spaceBottom>
      <Container>
        <InnerContainer>
          <h2>
            <Trans>Divas home lift specifications</Trans>
          </h2>
          <p>
            <Trans>
              Learn the differences between Divas Mrl and Divas Hydraulic
            </Trans>
          </p>
        </InnerContainer>
      </Container>

      <SpecsContainer>
        {" "}
        <Container
          center
          flex
          direction="column"
          bgColor="#fff"
          smallMaxWidth
          spaceBottom
        >
          <InnerContainer>
            <TabContainer>
              <TabMenu>
                <TabItem>
                  <Tab
                    disabled={isSelected}
                    isSelected={isSelected}
                    onClick={toggle}
                  >
                    <Trans>MRL</Trans>
                  </Tab>
                </TabItem>
                <TabItem>
                  <Tab
                    disabled={!isSelected}
                    onClick={toggle}
                    isSelected={!isSelected}
                  >
                    <Trans>Hydraulic</Trans>
                  </Tab>
                </TabItem>
              </TabMenu>
            </TabContainer>
          </InnerContainer>
          <InnerContainer>
            <SpecsWrapper isSelected={isSelected}>
              <SpecsList>
                {specsTitles.map((title, index) => (
                  <SpecsDiv>
                    <SpecsTitle key={index} title>
                      {title}:{" "}
                    </SpecsTitle>{" "}
                    <SpecsTitle key={index}>{mrlSpecs[index]}</SpecsTitle>
                  </SpecsDiv>
                ))}
              </SpecsList>
            </SpecsWrapper>
            <SpecsWrapper isSelected={!isSelected}>
              <SpecsList>
                {specsTitles.map((title, index) => (
                  <SpecsDiv>
                    <SpecsTitle key={index} title>
                      {title}:
                    </SpecsTitle>
                    <SpecsTitle key={index}>{hydraulicSpecs[index]}</SpecsTitle>
                  </SpecsDiv>
                ))}
              </SpecsList>
            </SpecsWrapper>
          </InnerContainer>
        </Container>
      </SpecsContainer>
    </Section>
  )
}

export default Specs
