import * as React from "react"
import { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Faq from "../components/specifications/faq"
import Home from "../components/specifications/home.js"
import Specs from "../components/specifications/spec"
import { graphql } from "gatsby"
const Specifications = () => {
  const [isSelected, setIsSelected] = useState(true)
  const toggle = () => {
    setIsSelected(!isSelected)
  }

  return (
    <Layout>
      <Seo title="Divas Specifications" />
      <Home />
      <Specs isSelected={isSelected} toggle={toggle} />
      <Faq />
    </Layout>
  )
}

export default Specifications
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
