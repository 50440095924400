import React from "react"
import { useState } from "react"
import {
  FaqAnswer,
  FaqTitle,
  FaqTitleWrapper,
  FaqWrapper,
  MinusIcon,
  PlusIcon,
} from "./specificationsElements"
const Accordion = ({ title, answer }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <FaqWrapper onClick={() => setIsActive(!isActive)}>
      <FaqTitleWrapper isOpen={isActive}>
        <FaqTitle>{title}</FaqTitle>
      </FaqTitleWrapper>
      <FaqAnswer isOpen={isActive}>{answer}</FaqAnswer>
    </FaqWrapper>
  )
}

export default Accordion
