import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Container from "../../styles/styled-component/Container"
import InnerContainer, {
  HeroInnerContainer,
} from "../../styles/styled-component/InnerContainer"
import { HeroSection } from "../../styles/styled-component/Section"
import bgImg from "../../images/BgkDivas.png"
import { HeroImage } from "../home/Home"
import { Trans } from "gatsby-plugin-react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import { useState, useEffect } from "react"
const Home = () => {
  const { language } = useI18next()

  const [direction, setDirection] = useState(false)

  useEffect(() => {
    const changeDirection = () => {
      if (language == "ar") {
        setDirection(!direction)
      } else {
        setDirection(direction)
      }
    }
    changeDirection()
  }, [])

  return (
    <HeroSection bgImage={bgImg} flex bgColor="#f2f2f2" positionX={direction}>
      <Container flex center direction="row">
        <InnerContainer centerText half>
          <h1>
            <Trans>Learn all about Divas</Trans>
          </h1>
          <p>
            <Trans>
              Find out the minimum required space for your home lift, the
              differences between Hydraulic and MRL and much more.
            </Trans>
          </p>
          <p>
            <Trans>
              Can't find what you need? Check out the frequently asked questions
              at the end of the page, or contact our Sales team.
            </Trans>
          </p>
        </InnerContainer>
        <HeroInnerContainer half>
          <HeroImage>
            <StaticImage
              height={500}
              src="../../images/BgkDivas.png"
              placeholder="blurred"
            ></StaticImage>
          </HeroImage>
        </HeroInnerContainer>
      </Container>
    </HeroSection>
  )
}

export default Home
