import React from "react"
import Container from "../../styles/styled-component/Container"
import { useState } from "react"
import Section from "../../styles/styled-component/Section"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Accordion from "./accordion"
import { LinkText } from "./specificationsElements"

import { Trans } from "gatsby-plugin-react-i18next"
const Faq = () => {
  const [isOpen, setIsOpen] = useState(false)

  const faqs = [
    {
      title: <Trans>What's the difference between MRL and Hydraulic?</Trans>,
      answer: (
        <div>
          <p>
            <Trans>
              Mrl is the latest techonolgy for home lifts, it provides a better
              experience both for the users and technicians
            </Trans>
          </p>
          <p>
            <Trans>
              The main advantages come from gettirng rid of the oil system used
              in Hydraulics, it is eco friendly as it gets rid of oil, it
              requires less maintanance over the years, and is the ideal
              solution in cold countries as low temperatures are know to cause
              issues with hydraulics and oil.
            </Trans>
          </p>
          <p>
            <Trans>
              Of course Hydraulic is still a valid solution, expecially in
              countries with a temparate climate, thanks to its lower price, and
              possibility of having an higher capacity and travel
            </Trans>
          </p>
        </div>
      ),
    },
    {
      title: <Trans>How much space is required for Divas?</Trans>,
      answer: (
        <div>
          <p>
            <Trans>
              Home elevators come in many different sizes, most are tailored for
              the customer needs if they don’t have much space or need
              wheelchair access, for example
            </Trans>{" "}
            <br />
            <strong>
              {" "}
              <Trans>the minimum space required for two people lift is :</Trans>
            </strong>
          </p>
          <p>
            <ul>
              <li>
                90cm x 90 cm <Trans>cabin width and length</Trans>
              </li>
              <li>
                {" "}
                <Trans>
                  {" "}
                  2400mm headroom(the space from the base of bottom floor to the
                  roof of the top floor)
                </Trans>
              </li>
              <li>
                {" "}
                <Trans>
                  150mm pit (the space needed under the cabin floor)
                </Trans>
              </li>
            </ul>
          </p>
          <p>
            <Trans>
              Wheelchair access requires a bit more space for the cabin:
            </Trans>
          </p>
          <p>
            <ul>
              {" "}
              <li>
                <Trans>90cm x 120 cm cabin width and length</Trans>
              </li>
              <li>
                <Trans>2400mm headroom</Trans>
              </li>
              <li>
                <Trans>150mm pit</Trans>
              </li>
            </ul>
          </p>{" "}
          <p>
            <Trans>
              If space isn’t a problem our recommended dimensions would be:
            </Trans>
          </p>
          <p>
            <ul>
              {" "}
              <li>
                <Trans>140cm x 160 cm cabin width and length</Trans>
              </li>
              <li>
                <Trans>2400mm headroom</Trans>
              </li>
              <li>
                <Trans>150mm pit</Trans>
              </li>
            </ul>
          </p>
        </div>
      ),
    },
  ]
  return (
    <Section minHeight>
      <Container>
        <InnerContainer>
          <h2>
            <Trans>Frequently asked questions</Trans>
          </h2>
          <p>
            <LinkText to="/contact">
              <Trans>
                Find an answer to your most common questions, or ask our sales
                team{" "}
              </Trans>
            </LinkText>
          </p>
        </InnerContainer>
      </Container>
      <Container flex center>
        <InnerContainer>
          {faqs.map(({ title, answer }) => (
            <Accordion title={title} answer={answer}></Accordion>
          ))}
        </InnerContainer>
      </Container>
    </Section>
  )
}

export default Faq
